import React, { Component } from 'react'

//firebase
import firebase from '../../../Firebase'

import { AiOutlineLoading } from 'react-icons/ai';
import { Row, Col } from 'react-simple-flex-grid';
import { Form } from 'react-bootstrap'

class Register extends Component {

  constructor () {
    super()
    this.fs = firebase.firestore() //firestore!
    this.state = {
      loading: false,
      name: '',
      email: '',
      password: '',
      confirm_password: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount(){
    let set = this
    firebase.auth().onAuthStateChanged(function(user) {
      if(user){
        set.props.history.push('/')
      }
    })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  registerUser(){
    let set = this
    var name_regex = /^[a-zA-Z ]{2,30}$/;

    var email_regex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if(set.state.name === ''){
      set.setState({ name_e: 'Required' })
    }
    else if(set.state.name.match(name_regex) === null){
      set.setState({ name_e: 'Valid name required' })
    }
    else if(set.state.email === ''){
      set.setState({ email_e: 'Required' })
    }
    else if(set.state.email.match(email_regex) === null){
      set.setState({ email_e: 'Valid email required' })
    }
    else if(set.state.password === ''){
      set.setState({ password_e: 'Required' })
    }
    else if(set.state.confirm_password === ''){
      set.setState({ confirm_password_e: 'Required' })
    } 
    else if(set.state.password !== set.state.confirm_password){
      set.setState({ confirm_password_e: 'Passwords dont match' })
    } else {
      set.setState({
        name_e: '',
        email_e: '',
        password_e: '', 
        confirm_password_e: '', 
        loading: true
      })
      set.registerProcess()
    }
  }

  registerProcess(){
    let set = this
    firebase.auth().createUserWithEmailAndPassword(set.state.email, set.state.password)
    .then(function(user) {
        let date_now = Date.now().toString();
        let unq_id = date_now.substr(date_now.length - 6)
        set.sendVerificationEmail(unq_id)
    }).catch(function(error) {
        set.setState({ 
          loading: false, 
          error: error.message 
        })
    });
  }

  sendVerificationEmail(unq_id){
    let set = this
    set.fs.collection('admin_email_verification').add({
        to: set.state.email,
        message: {
            subject: 'Verification code',
            html: '<h3>Verify your email to finish signing up for Ceynema.</h3>Thank you for choosing Ceynema.<br/><h1>' + unq_id + '</h1>Please enter this code to verify your identity.',
        }
    }).then(() => set.addUserNametoAuth(unq_id) );
  }

  addUserNametoAuth(unq_id){
    let set = this
    var user = firebase.auth().currentUser;
    user.updateProfile({
        displayName: set.state.name
    }).then(function() {
        //console.log("Profile Name Updated Success")
        set.addUserInfotoCollection(user.uid, unq_id)
    }).catch(function(error) {
        console.log(error)
    })
  }

  addUserInfotoCollection(uid, unq_id){
    let set = this
    set.fs.collection("users").doc(uid).set({
        name: set.state.name,
        email: set.state.email,
        email_unq_id: unq_id,
        email_unq_verify: false,
        uid: uid,
        created: firebase.firestore.FieldValue.serverTimestamp()
    })
    .then(function() {
        console.log("User created in firebase...");
        set.setState({ loading: false, })
        //set.props.history.push('/')
        window.location.reload();
    })
    .catch(function(error) {
        console.error("Error (): ", error);
    });
  }


  render() {
    return (
      <div>
        
        <Row className="mt-5">
          <Col><h4>Sign up now</h4></Col>
        </Row>

        <Form className="mt-3 mb-2">

          <Row gutter={20} className="mb-2">
            <Col xs={12} lg={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control 
                  type="text" 
                  className="bg-dark text-white"
                  placeholder="Your name" 
                  value={ this.state.name }
                  name="name"
                  onChange={ this.handleInputChange.bind(this) }
                  onKeyPress={() => this.setState({ name_e: ''})}
                />
                {
                  this.state.name_e ? 
                  <Form.Text className="text-danger">{this.state.name_e}</Form.Text> : <></>
                }
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                  type="email" 
                  className="bg-dark text-white"
                  placeholder="Your email" 
                  value={ this.state.email }
                  name="email"
                  onChange={ this.handleInputChange.bind(this) }
                  onKeyPress={() => this.setState({ email_e: ''})}
                />
                {
                  this.state.email_e ? 
                  <Form.Text className="text-danger">
                    { this.state.email_e }
                  </Form.Text> : <></>
                }
              </Form.Group>
            </Col>
          </Row>

          <Row gutter={20} className="mb-2">
            <Col xs={12} lg={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Password</Form.Label>
                <Form.Control 
                  type="password" 
                  className="bg-dark text-white"
                  placeholder="Your password" 
                  value={ this.state.password }
                  name="password"
                  onChange={ this.handleInputChange.bind(this) }
                  onKeyPress={() => this.setState({ password_e: ''})}
                />
                {
                  this.state.password_e ? 
                  <Form.Text className="text-danger">
                    { this.state.password_e }
                  </Form.Text> : <></>
                }
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control 
                  type="password" 
                  className="bg-dark text-white"
                  placeholder="Retype password" 
                  value={ this.state.confirm_password }
                  name="confirm_password"
                  onChange={ this.handleInputChange.bind(this) }
                  onKeyPress={() => this.setState({ confirm_password_e: ''})}
                />
                {
                  this.state.confirm_password_e ? 
                  <Form.Text className="text-danger">
                    { this.state.confirm_password_e }
                  </Form.Text> : <></>
                }
              </Form.Group>
            </Col>
          </Row>
          
          {
            this.state.loading ? 
            <div 
              className="ceyButtonRedLoading btn btn-block text-center">
              Sign up <AiOutlineLoading className="spin"/>
            </div>
            :
            <div 
              onClick={() => this.registerUser() }
              className="ceyButtonRed btn btn-block text-center">
              Sign up 
            </div>
          }

          {
            this.state.error ? 
            <Form.Text className="text-danger text-center">
              { this.state.error }
            </Form.Text> : <></>
          }
          
          
        </Form>
        
      </div>
    )
  }
}


export default Register