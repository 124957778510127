import React, { Component } from 'react'

//firebase
import firebase from '../../../Firebase'


import { AiOutlineLoading } from 'react-icons/ai';
import { Row, Col } from 'react-simple-flex-grid';
import { Form } from 'react-bootstrap'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";

class Reset extends Component {

  constructor () {
    super()
    this.state = {
      loading: false,
      email: '',
      resetSuccess: false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount(){
    let set = this
    firebase.auth().onAuthStateChanged(function(user) {
      if(user){
        set.props.history.push('/')
      }
    })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }


  loginUser(){
    let set = this

    var email_regex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if(set.state.email === ''){
      set.setState({ email_e: 'Required' })
    } 
    else if(set.state.email.match(email_regex) === null){
      set.setState({ email_e: 'Valid email required' })
    }
    else {
      set.setState({
        email_e: '',
        loading: true
      })
      set.resetProcess()
    }
  }

  resetProcess(){
    let set = this    
    firebase.auth().sendPasswordResetEmail(set.state.email).then(function() {
      set.setState({ 
        loading: false, 
        resetSuccess: true,
      })
    }).catch(function(error) {
      console.log("error ", error)
      // An error happened.
    });
  }



  render() {
    return (
      <div>
        
        {
          this.state.resetSuccess ? 
          <h5 className="text-warning text-center">
            Reset password email sent to your email, { this.state.email }
          </h5> : 
          <>
            <Row className="mt-5">
              <Col><h4>Reset password</h4></Col>
            </Row>

            <Form className="mt-3 mb-2">

              <Row gutter={20} className="mb-2">
                <Col xs={12} lg={12} >
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Your Email</Form.Label>
                    <Form.Control 
                      type="email" 
                      className="bg-dark text-white"
                      placeholder="Your email" 
                      value={ this.state.email }
                      name="email"
                      onChange={ this.handleInputChange.bind(this) }
                      onKeyPress={() => this.setState({ email_e: ''})}
                    />
                    {
                      this.state.email_e ? 
                      <Form.Text className="text-danger">{this.state.email_e}</Form.Text> : <></>
                    }
                  </Form.Group>
                </Col>
              </Row>

              {
                this.state.loading ? 
                <div 
                  className="ceyButtonRed btn btn-block text-center">
                  Please Wait <AiOutlineLoading className="spin"/>
                </div>
                :
                <div 
                  onClick={() => this.loginUser() }
                  className="ceyButtonRed btn btn-block text-center">
                  Reset Password
                </div>
              }
            

              {
                this.state.error ? 
                <Form.Text className="text-danger text-center">
                  { this.state.error }
                </Form.Text> : <></>
              }

              
            </Form>
          </>
        }
        
        
        
      </div>
    )
  }
}


export default withRouter(Reset)