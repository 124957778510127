import React, { Component } from 'react'



import { Container, Button, Modal } from 'react-bootstrap'
import { Row, Col } from 'react-simple-flex-grid';

import { FiPlayCircle } from 'react-icons/fi';

import YouTube from 'react-youtube';


const opts = {
  height: '440',
  width: '100%',
  playerVars: {
    autoplay: 0,
  },
};

export default class Index extends Component {
  constructor () {
    super()
    this.state = {
      trailerMode: false,
    }

  }

  render(){
    return (
      <div>
        <div onClick={() => this.setState({ trailerMode: true })}
            className="btn btn-block ceyButtonRed btn-sm">
            <FiPlayCircle className="icon_margin" /> Trailer
        </div>

        <Modal 
            className="darkBG"
            show={this.state.trailerMode} 
            size="lg" centered
            onHide={() => this.setState({ trailerMode: false })}>
            <Modal.Header closeButton className="darkBG">
            <Modal.Title>{ this.props.title } Trailer</Modal.Title>
            </Modal.Header>
            <Modal.Body className="darkBG">

            <YouTube videoId="eCNpx3PmtNw" opts={opts} onReady={this._onReady} />

            </Modal.Body>
        </Modal>
            
  
      </div>
    );
  }
}


