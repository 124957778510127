
import AppRouter from './Router'
//import logo from './logo.svg';


import { Button } from 'react-bootstrap';




function App(props) {

  return (
      <div className="App">
        {/* <img src={logo} className="App-logo" alt="logo" /> */} 
        <AppRouter />
      </div>
  );
}

export default App;
