import React, { Component } from 'react'
//firebase
import firebase from '../../Firebase'

import { FiPlayCircle } from 'react-icons/fi';

import { Container, Button } from 'react-bootstrap'
import { Row, Col } from 'react-simple-flex-grid';

import moment from 'moment';
import 'moment-timezone';
import md5 from 'crypto-js/md5';


class BuyButton extends Component { 
    constructor () {
      super()
      this.fs = firebase.firestore()
      this.state = {
        loading: true,
      }
    }

    componentDidMount(){
      let set = this
    }

    pay(){
      let set = this
      let auth_user = firebase.auth().currentUser
      let timeStamp = moment().format('x');

      let merchantSecret  = '0bd651489ae2c5cfa67ee14c1106eadb';
      let hashedSecret = md5(merchantSecret).toString().toUpperCase();
      let amountFormated  = parseFloat( this.props.amount ).toLocaleString( 'en-us', { minimumFractionDigits : 2 } ).replaceAll(',', '')
      let hash = md5('213729' + timeStamp + amountFormated + this.props.currency + hashedSecret).toString().toUpperCase();

      var payment = {
        sandbox: false, // if the account is sandbox or real
        merchant_id: '213729', //'213729', // Replace your Merchant ID - sandbox 1222577
        return_url: 'http://sample.com/return',
        cancel_url: 'http://sample.com/cancel',
        notify_url: 'http://sample.com/notify',
        first_name: this.props.user,
        last_name: '',
        email: auth_user.email,
        phone: '',
        address: '',
        city: this.props.city,
        country: this.props.country,
        order_id: timeStamp,
        items: this.props.name.name,
        amount: this.props.amount,
        currency: this.props.currency,
        hash: hash,
      }; 

      // Called when user closes the payment without completing
      window.payhere.onDismissed = function onDismissed() {
        console.log("Payment dismissed")
      };

      window.payhere.onDismissed = function onDismissed() { 
        
      }

      // Called when error happens when initializing payment such as invalid parameters
      window.payhere.onError = function onError(error) {
        console.log("Error:"  + error);
      };

      // Called when user completed the payment. It can be a successful payment or failure
      window.payhere.onCompleted = function onCompleted(orderId) {
        console.log("Payment completed. OrderID:" + orderId);
        let auth = firebase.auth().currentUser
        set.fs.collection("movie_purchased").add({
          item_key: set.props.name.key,
          item: set.props.name.name,
          amount: set.props.amount,
          currency: set.props.currency,
          buyer: set.props.user,
          email: auth.email,
          uid: auth.uid,
          city: set.props.city,
          country: set.props.country,
          orderId: orderId,
        })
        .then(function(docRef) {
          console.log("Document written with ID: ", docRef.id);
          set.updateFireStore(orderId)
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
        //Note: validate the payment and show success or failure page to the customer
      };

      window.payhere.startPayment(payment);
    }

    updateFireStore(orderId){
      let set = this
      let auth = firebase.auth().currentUser
      set.fs.collection("user_purchases").doc(auth.uid).collection('list').doc(set.props.name.key).set({
        uid: auth.uid,
        item_key: set.props.name.key,
        item: set.props.name.name,
        amount: set.props.amount,
        currency: set.props.currency,
        buyer: set.props.user,
        email: auth.email,
        uid: auth.uid,
        city: set.props.city,
        country: set.props.country,
        orderId: orderId,
      }).then(function(docRef) {
        console.log("user_purchases written ");
        window.location.reload()
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
    }

    render(){ 
      return (
        <>
          <div className="btn btn-block ceyButtonRed btn-lg" onClick={() => this.pay() }>
            <FiPlayCircle className="icon_margin" /> Buy Now
          </div>
        </>
      )
    }
}

export default BuyButton;