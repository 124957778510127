import React, { Component } from 'react'

//firebase
import firebase from './../../../Firebase'
import Header from '../../Components/Header'

import {
  BrowserRouter as Router,
  Link,
  withRouter,
} from "react-router-dom";

import { Container, Table, Button, Tab, Nav } from 'react-bootstrap'
import { Row, Col } from 'react-simple-flex-grid';

class Index extends Component {
    
  constructor () {
    super()
    this.state = {
      loading: true,
      isAmdin: false,
      list: [],
    }
  }

  componentDidMount(){
    let set = this
    let current_user = firebase.auth().currentUser
    if(current_user.email === 'joeljeru1@gmail.com' || current_user.email === 'pathu122@gmail.com'){
      set.setState({
        isAmdin: true,
      })

      firebase.firestore().collection("movie_purchased").orderBy("orderId", "desc")
      .onSnapshot((querySnapshot) => {
          var list = [];
          querySnapshot.forEach((doc) => {
            list.push(doc.data());
          });
          set.setState({ list, loading: false, })
      });

    } else {
      set.props.history.push('/')
    }
  }

  
  render() {
    if(this.state.loading){
      return ( <>loading..</> )
    } else {
      {
        if(this.state.isAmdin){
          return (
            <div>
                <Header />
    
                <Container className="mt-2">
                  <Row>
                    <Col>
                      <h2>Purchases</h2>
                    </Col>
                  </Row>
    
                  <Row>
                    <Col className="text-white" lg={12}>
                      <Table responsive className="text-white">
                        <thead>
                          <tr>
                            <th>Order #</th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Area</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.list.map((single, index) =>
                              <tr key={ single.orderId }>
                                <td>{ single.orderId }</td>
                                <td>{ single.email }</td>
                                <td>{ single.buyer }</td>
                                <td>{ single.currency } { single.amount }</td>
                                <td>{ single.city } { single.country }</td>
                              </tr>
                            )
                          }
                          
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
        
            </div>
          );
        } else {
          return (
            <div>
        
               Noth authorized 
        
            </div>
          );
        }
      }
    }
  }
}


export default withRouter(Index)