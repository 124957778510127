import { Container, Button } from 'react-bootstrap'
import { Row, Col } from 'react-simple-flex-grid';


function Footer(props) {
  return (
    <div>
        <Container>
          <Row justify="center">
            <Col className="pt-3 pb-3 footerLeftText text-center">
              <p>© Copyright 2020 ceynema. All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
    </div>
  );
}

export default Footer;
