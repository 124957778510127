import React, {Component} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink
} from "react-router-dom";
import { Container, Row, Col, Nav } from 'react-bootstrap'

//firebase
import firebase from './Firebase'

//public routes
import PublicHome from './Views/Public/Home'

//common
import SingleMovie from './Views/SingleMovie/SingleMovie'

//authentication
import LoginOrRegister from './Views/Authentication/Index'
import ResetPassword from './Views/Authentication/ResetPassword'

//admin pages
//import AdminMoviesList from './Views/Admin/Movies/Movies'
//import AdminMovieCreate from './Views/Admin/Movies/Create'
//import AdminUsersList from './Views/Admin/Users/Users'
import AdminPurchases from './Views/Admin/Purchase/Index'

class AppRouter extends Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: true,
          isAdmin: false,
          auth_user: undefined,
          admin_menu: true,
        };
    }

    componentDidMount(){
      let set = this
      firebase.auth().onAuthStateChanged(function(user) {
        if(user){
          if(user.email === 'joeljeru1@gmail.com' || user.email === 'pathu122@gmail.com'){
            set.setState({
              auth_user: user,
              isAdmin: true,
            })
          } else {
            set.setState({
              auth_user: user,
              isAdmin: false,
            })
          }
        } else {
          set.setState({
            auth_user: undefined,
          })
        }
      })
    }

    render(){
        return(
          <Router>
              {
                this.state.auth_user === undefined ?
                <Switch>
                  <Route exact path="/">
                    <PublicHome />
                  </Route>
                  {/* <Route path="/movie/:id">
                    <SingleMovie />
                  </Route> */}
                  <Route path="/watch-now/">
                    <SingleMovie />
                  </Route>
                  
                  <Route path="/signin">
                    <LoginOrRegister />
                  </Route>
                  <Route path="/passwordreset">
                    <ResetPassword />
                  </Route>
                  <Route path="/dashboard">
                    <PublicHome />
                  </Route>
                </Switch>
                :
                <Switch>
                  <Route exact path="/">
                    <PublicHome />
                  </Route>
                  {/* <Route path="/movie/:id">
                    <SingleMovie />
                  </Route> */}
                  <Route path="/watch-now/">
                    <SingleMovie />
                  </Route>

                  {
                    this.state.isAdmin ? 
                    <Route path="/superuser/">
                      <AdminPurchases />
                    </Route> : <></>
                  }
                  


                  { /* ADMIN menu */}
                  {/* {
                    this.state.admin_menu ? 
                    <>
                      <Container>
                        <Row>
                          <Col sm={3} className="pt-3">
                            <h4>Ceynema admin</h4>
                            <Nav variant="pills" className="flex-column mt-4">
                              <Nav.Item>
                                <NavLink to="/admin/movies" className="nav-link" activeClassName="active">Movies</NavLink>
                              </Nav.Item>
                              <Nav.Item>
                                <NavLink to="/admin/users" className="nav-link">Users</NavLink>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={9} className="pt-3">
                            <Route exact path="/admin/movies">
                              <AdminMoviesList />
                            </Route>
                            <Route exact path="/admin/movie/create">
                              <AdminMovieCreate />
                            </Route>
                            <Route path="/admin/users">
                              <AdminUsersList />
                            </Route>
                          </Col>
                        </Row>
                      </Container>
                      
                    </> : <></>
                  } */}
                </Switch>
              }
          </Router>
        )
    }

}

export default AppRouter;