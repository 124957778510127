import React, { Component } from 'react'
//firebase
import firebase from '../../Firebase'

import { Container, Button, Table } from 'react-bootstrap'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";

import { Row, Col } from 'react-simple-flex-grid';
import logo from './../../images/logo.png';


class Header extends Component {

  constructor () {
    super()
    this.fs = firebase.firestore()
    this.state = {
      loading: true,
      list: [],
      logged: false,
      auth_user: []
    }
  }

  componentDidMount(){
    let set = this
    firebase.auth().onAuthStateChanged(function(user) {
      if(user){
        set.setState({
          logged: true,
          auth_user: user,
        })
      } else {
        set.setState({ 
          logged: false, 
        })
      }
    })

  }

  logOut(){
    let set = this
    firebase.auth().signOut().then(function() {
        //set.props.history.push('/')
        window.location.reload();
    }).catch(function(error) {
        console.log(error)
    });
  }


  render(){
    return (
      <div>

          <Container>
           
            <Row>
              <Col span={3} className="pt-3 pb-3 logoWrap">
                <Link to="/">
                  <img src={logo} className="App-logo" alt="ceynema sri lanka" style={{ width: 250  }} /> 
                </Link>
              </Col>
              <Col xs={6} lg={7} className="text-right pt-4 pb-3 pr-4 ">
              
                <div className="d-none d-sm-block">
                {
                  this.state.logged ? 
                  <div className="mt-1">
                    Signed in as <strong>
                      { this.state.auth_user.displayName }
                    </strong>
                  </div> : <></>
                }
                </div>
                
              </Col>
              <Col xs={3} lg={2} className="text-right pt-4 pb-3">
                {
                  this.state.logged ? 
                  <div 
                    className="ceyButtonRed btn btn-sm btn-block" 
                    onClick={() => this.logOut() }>Sign out</div>
                  :
                  <Link to="/signin" className="ceyButtonRed btn btn-sm btn-block">Sign in</Link>
                }
                
              </Col>
            </Row>

            
          </Container>
      </div>
    );
  }
}

export default withRouter(Header);
