import React, { Component } from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

import { Row, Col } from 'react-simple-flex-grid';
import { Container, Button, Form } from 'react-bootstrap'

import Register from './Components/Register'
import Login from './Components/Login'
import ResetPasword from './Components/Reset'

export default class Index extends Component {

  constructor () {
    super()
    this.state = {
        mode: 'login'
    }

  }

  /* sendAuthenticationCode(){
    let set = this
    const regex = /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

    let matches;
    matches = regex.exec(set.state.phone);

    console.log("matches ", matches)
  } */



  render() {
    return (
      <div className="authbg">
        <Header />

        <Container>
          <Row gutter={40} justify="center">
            <Col xs={10} lg={5} >

              {
                this.state.mode === 'reset' ?
                <>
                  <ResetPasword />
                  <>
                    <div className="mb-5 authModeSwitchWrap text-center">
                      <small>Switch back to <span className="authModeSwitch" onClick={() => this.setState({ mode: 'login'})}>Login</span></small>
                    </div>
                  </>
                </>
                :
                <>
                {
                  this.state.mode === 'register' ?
                  <>
                    <Register />
                    <div className="mb-2 authModeSwitchWrap text-center">
                      <small>Already have an account? <span className="authModeSwitch" onClick={() => this.setState({ mode: 'login'})}>Sign in</span></small>
                    </div>
                  </>
                  :
                  <>
                    <Login />
                    <div className="mb-2 authModeSwitchWrap text-center">
                      <small>Don't have an account? <span className="authModeSwitch" onClick={() => this.setState({ mode: 'register'})}>Sign up</span></small>
                    </div>
                  </>
                }
                <>
                  <div className="mb-5 authModeSwitchWrap text-center">
                    <small>Forgot your password? <span className="authModeSwitch" onClick={() => this.setState({ mode: 'reset'})}>Reset</span></small>
                  </div>
                </>
                </>
              }


              

                
  
            </Col>
          </Row>
        </Container>
        

        <Footer />
      </div>
    )
  }
}
