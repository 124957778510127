import React, { Component } from 'react'
//firebase
import firebase from '../../Firebase'

import Header from '../Components/Header'
import Footer from '../Components/Footer'

import { Container, Button } from 'react-bootstrap'
import { Row, Col } from 'react-simple-flex-grid';

//watch button
import WatchNowButton from './../Components/WatchNowButton'
import TrailerButton from './Components/TrailerMode'

//light box added
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const options = {
  settings: {
    disablePanzoom: true
  },
  buttons: {
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false,
    showAutoplayButton: false,
  },
  caption: {
  }
};


export default class Index extends Component {
  constructor () {
    super()
    this.fs = firebase.firestore()
    this.state = {
      loading: true,
      single: [],
    }

  }

  componentDidMount(){
    let set = this
    set.getMovie()
  }

  getMovie(){
    let set = this
    var docRef = set.fs.collection("movies").doc("5wbdo0u2m80CvacHBnsP");
    docRef.get().then(function(doc) {
      if (doc.exists) {
        set.setState({
          single: { key: doc.id, ...doc.data() },
          loading: false,
        })
      }
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });

  }

  render(){
    if(this.state.loading){
      return(<></>)
    } else {
      return (
        <div>
    
            <Header />
  
  
            <Container className="mt-3 mb-3">
              <Row gutter={30} justify="center">
                <Col xs={12} lg={3} className="fullWidthImage">
                  <div className="coverImageWrap">
                    <img src={this.state.single.poster} />
                    <TrailerButton title={ this.state.single.name } />
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <h1 className="display-4">{this.state.single.name}</h1>
                  <p className="mb-3 text-secondary">
                    { this.state.single.year } - { this.state.single.category }
                  </p>
  
                  <Row>
                    <Col xs={12} lg={6}>
                      <WatchNowButton single={this.state.single} />
                    </Col>
                  </Row>
  
  
                  <p className="mb-3 mt-3">{ this.state.single.discription }</p>
  
                  
                </Col>
              </Row>
  
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <Row gutter={30} justify="center" className="mt-5">
                    <Col xs={12} lg={9} className="fullWidthImage">
                      <Row gutter={5}>
                        {
                          this.state.single.gallery.map((single, index) =>
                            <Col xs={6} lg={4} key={index}>
                              <img src={ single } className="singleGalleryItem" />
                            </Col>
                          )
                        }
                      </Row>
                      
                    </Col>
                  </Row>
                </SRLWrapper>
              </SimpleReactLightbox>
              
            </Container>
  
    
    
            <Footer />
    
    
        </div>
      );
    }
  }
}


