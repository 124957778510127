import React, { Component } from 'react'
//firebase
import firebase from '../../Firebase'

import Header from '../Components/Header'
import Footer from '../Components/Footer'

import { Container, Button, Modal } from 'react-bootstrap'
import { Row, Col } from 'react-simple-flex-grid';

import { Link } from "react-router-dom";
import { FiPlayCircle } from 'react-icons/fi';

import YouTube from 'react-youtube';


const opts = {
  height: '440',
  width: '100%',
  playerVars: {
    autoplay: 0,
  },
};

export default class Index extends Component {
    
  constructor () {
    super()
    this.fs = firebase.firestore()
    this.state = {
        list: [],
        trailerMode: false,
    }

  }

  componentDidMount(){
    let set = this
    //set.getList()
  }

  /* getList(){
    let set = this
    set.fs.collection("movies")
    .get()
    .then(function(querySnapshot) {
      let list = []
      querySnapshot.forEach(function(doc) {
          list.push({ key: doc.id, ...doc.data() })
      });
      set.setState({ list, })
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
  } */
  
  render() {
    return (
      <div>
  
        <Header />

        <div style={{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/ceynema-app.appspot.com/o/movie-poster%2FMOVIE-1%2Fkomali_header.jpg?alt=media&token=3eddf8de-1297-491b-9b82-0a53e75c1948)', height: 600, backgroundSize: 'cover', backgroundPosition: 'center',  }} className="headerSlider">
          <Container>
            <Row className="pt-5">
              <Col className="textCenter mt-5 pt-5">
                <h1 className="display-3">Komaali Kings</h1>
                <h2>The revival of Sri Lankan Tamil cinema</h2>
                <Row justify="center" gutter={20}>
                  <Col>
                    <Link 
                    to={'/watch-now' } 
                    className="ceyButtonRed btn mt-2">
                      <FiPlayCircle style={{ marginTop: -5 }}/> Watch Now 
                    </Link>
                  </Col>
                  <Col>
                    <Button 
                    className="ceyButtonRed mt-2"
                    onClick={() => this.setState({ trailerMode: true })}>
                      <FiPlayCircle style={{ marginTop: -5 }}/> Watch Trailer 
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Modal 
            className="darkBG"
            show={this.state.trailerMode} 
            size="lg" centered
            onHide={() => this.setState({ trailerMode: false })}>
            <Modal.Header closeButton className="darkBG">
            <Modal.Title>{ this.props.title } Komaali Kings Trailer</Modal.Title>
            </Modal.Header>
            <Modal.Body className="darkBG">

            <YouTube videoId="eCNpx3PmtNw" opts={opts} onReady={this._onReady} />

            </Modal.Body>
        </Modal>
        </div>

        <Container className="mt-5 mb-5">
          <Row justify="center">
            <Col xs={12} lg={8} >
              <YouTube videoId="YjF9c5cVMeE" opts={opts} onReady={this._onReady} />
            </Col>
          </Row>
        </Container>

        {/* <HomeSlider /> */}

          {/* <Container>
            <Row>
              
              <Recommended list={this.state.list} />
            </Row>
          </Container> */}
  
  
        <Footer />
  
  
      </div>
    );
  }
}
