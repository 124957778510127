//import firebase
import firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions' 

const config = {
    apiKey: "AIzaSyCRNLSp6Yxg5ta-GehUU_nwguao0XRGD0Y",
    authDomain: "ceynema-app.firebaseapp.com",
    projectId: "ceynema-app",
    storageBucket: "ceynema-app.appspot.com",
    messagingSenderId: "1047511004042",
    appId: "1:1047511004042:web:50e6d4f1cb74ef0e2d8e73",
    measurementId: "G-6HF8599B85"
};
firebase.initializeApp(config);

export default firebase;

