import React, { Component } from 'react'
//firebase
import firebase from '../../Firebase'
import { FiPlayCircle } from 'react-icons/fi';


import { Container, Button, Modal } from 'react-bootstrap'
import { Row, Col } from 'react-simple-flex-grid';

import BuyNowButton from './BuyNowButton'

import axios from 'axios'
import ReactPlayer from 'react-player'


export default class Index extends Component {
  constructor () {
    super()
    this.fs = firebase.firestore()
    this.state = {
      loading: true,
      countryName: undefined,
      city: undefined,
      country: undefined,
      user: undefined,
      purchased: false,
      showMovie: false,
    }

  }

  componentDidMount(){
    let set = this
    //right click
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    set.getGeoInfo()
  }

  getGeoInfo(){
    let set = this
    let user = firebase.auth().currentUser
    console.log("token ", user)
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      set.setState({
          countryName: data.country_name,
          city: data.city,
          country: data.country,
          user: user ? user.displayName : undefined,
          loading: false,
      });
      set.getList()
    }).catch((error) => {
        console.log(error);
    });
  }

  getList(){
    let set = this
    let auth_user = firebase.auth().currentUser

    var docRef = set.fs.collection("user_purchases").doc(auth_user.uid).collection('list').doc('5wbdo0u2m80CvacHBnsP');

    docRef.get().then(function(doc) {
        if (doc.exists) {
          set.setState({ purchased: true, })
        } else {
          set.setState({ purchased: false, })
        }
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });
  }

  rightClick(){
    let set = this
    /* firebase.auth().signOut().then(function() {
        //set.props.history.push('/')
        window.location.reload();
    }).catch(function(error) {
        console.log(error)
    }); */
  }


  render(){
    if(this.state.loading){
      return(<></>)
    } else {
      return (
        <div>
          {
            this.state.user === undefined ? 
              <a href="/signin" className="btn btn-block ceyButtonRed btn-lg">
                <FiPlayCircle className="icon_margin" /> Sign in to watch
              </a>
            :
            <>
            {
              this.state.purchased ?
              <span>
                <div className="btn btn-block ceyButtonRed btn-lg" onClick={() => this.setState({ showMovie: true })}>
                  <FiPlayCircle className="icon_margin" /> Watch Now
                </div>
                <Modal 
                    className="darkBG"
                    show={this.state.showMovie} 
                    size="lg" centered
                    onHide={() => this.setState({ showMovie: false })}>
                    <Modal.Header closeButton className="darkBG">
                    <Modal.Title>Komaali Kings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="darkBG">

                    <ReactPlayer url={this.props.single.file} 
                      playing={true}
                      width="100%"
                      controls={ true }
                      controlsList="nodownload"
                      //muted={true}
                      config={{
                        file: {
                          forceVideo: true,
                          attributes: {
                            controlsList: "nodownload"
                          }
                        },
                      }}
                    />

                    </Modal.Body>
                </Modal>
              </span>
              :
              <div>
                <BuyNowButton 
                  orderId={45896588}
                  name={this.props.single}
                  amount={ this.state.country === 'LK' ? this.props.single.price : this.props.single.price_usd }
                  currency={ this.state.country === 'LK' ? 'LKR' : 'USD' }
                  country={ this.state.countryName }
                  city={ this.state.city }
                  user={ this.state.user }
                />
              </div>
            }
            </>
          }

        </div>
      );
    }
  }
}


