import React, { Component } from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

//firebase
import firebase from '../../Firebase'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";

import { Row, Col } from 'react-simple-flex-grid';
import { Container, Button, Form } from 'react-bootstrap'
import { AiOutlineLoading } from 'react-icons/ai';

const queryString = require('query-string');

class ResetPassword extends Component {

  constructor () {
    super()
    this.state = {
        loading: true,
        email: undefined,
        code: undefined,
        password: '',
        confirm_password: '',
        updatingPassword: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  componentDidMount(){
    let set = this
    const parsed = queryString.parse(this.props.location.search);

    firebase.auth().verifyPasswordResetCode(parsed.oobCode)
    .then(function(email) {
      set.setState({ 
        loading: false,
        email,
        code: parsed.oobCode,
      })
    })
    .catch(function(error) {
      console.log(error.message)
      set.props.history.push('/')
    })
  }

  updatePassword(){
    let set = this

    if(set.state.password === ''){
      set.setState({ password_e: 'Required' })
    }
    else if(set.state.confirm_password === ''){
      set.setState({ confirm_password_e: 'Required' })
    } 
    else if(set.state.password !== set.state.confirm_password){
      set.setState({ confirm_password_e: 'Passwords dont match' })
    } else {
      set.setState({
        password_e: '',
        confirm_password_e: '',
        updatingPassword: true
      })
      set.updatePasswordProcess()
    }
  }

  updatePasswordProcess(){
    let set = this
    firebase.auth().confirmPasswordReset(set.state.code, set.state.password)
    .then(function() {
      set.setState({
        password_e: '',
        confirm_password_e: '',
        updatingPassword: false,
      })
      set.props.history.push('/signin')
    })
    .catch(function(err) {
      console.log(err)
      // Invalid code
    })
  }

  render() {
    
    if(this.state.loading){
      return(<>Please wait.. verifying</>)
    } else {
      return (
        <div className="authbg">
          <Header />
  
          <Container>
            <Row gutter={40} justify="center">
              <Col xs={10} lg={5} >
  
              <Row className="mt-5">
            <Col><h4>Sign in</h4></Col>
          </Row>
  
          <Form className="mt-3 mb-2">
  
            <Row gutter={20} className="mb-2">
              <Col xs={12} lg={12} >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Your Email</Form.Label>
                  <Form.Control 
                    type="email" 
                    className="bg-dark text-white"
                    value={ this.state.email }
                    name="email"
                    disabled="true"
                  />
                </Form.Group>
              </Col>

              <Col xs={12} lg={12}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control 
                    type="password" 
                    className="bg-dark text-white"
                    placeholder="Your new password" 
                    value={ this.state.password }
                    name="password"
                    onChange={ this.handleInputChange.bind(this) }
                    onKeyPress={() => this.setState({ password_e: ''})}
                  />
                  {
                    this.state.password_e ? 
                    <Form.Text className="text-danger">{this.state.password_e}</Form.Text> : <></>
                  }
                </Form.Group>
              </Col>

              <Col xs={12} lg={12}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control 
                    type="password" 
                    className="bg-dark text-white"
                    placeholder="Re-type your new password" 
                    value={ this.state.confirm_password }
                    name="confirm_password"
                    onChange={ this.handleInputChange.bind(this) }
                    onKeyPress={() => this.setState({ confirm_password_e: ''})}
                  />
                  {
                    this.state.confirm_password_e ? 
                    <Form.Text className="text-danger">{this.state.confirm_password_e}</Form.Text> : <></>
                  }
                </Form.Group>
              </Col>
            </Row>

            
  
            {
              this.state.updatingPassword ? 
              <div 
                className="ceyButtonRed btn btn-block text-center">
                Please Wait <AiOutlineLoading className="spin"/>
              </div>
              :
              <div 
                onClick={() => this.updatePassword() }
                className="ceyButtonRed btn btn-block text-center">
                Reset Password
              </div>
            }
           
  
            {
              this.state.error ? 
              <Form.Text className="text-danger text-center">
                { this.state.error }
              </Form.Text> : <></>
            }
          </Form>
                  
    
              </Col>
            </Row>
          </Container>
          
  
          <Footer />
        </div>
      )
    }
  }
}


export default withRouter(ResetPassword)